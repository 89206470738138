.parent {
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    column-gap: 2rem;
    row-gap: 2rem;
}

.nota-link {
    display: flex;
    flex-direction: column;
}

.grid-item {
    padding-bottom: 0.8rem;
    border-bottom: 1px solid var(--light-gray);
}

.note-author {
    color: var(--medium-black);
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 0.9rem;
}

.grid-item img {
    width: 100%;
}

.grid-item h3 {
    font-size: 1.25rem;
    font-family: "Roboto", sans-serif;
    padding: 0.25rem 0 0.75rem 0;
    font-weight: 400;
    margin: 0;
    line-height: 1.3;
}

.grid-item .highlighted-text {
    color: var(--sky-blue)
}

.sponsored a {
    position: relative;
}

.img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.sponsored .badge{
    display: flex;
    align-items: center;
    position: absolute;
    -moz-transform: translateY(-140%);
    -ms-transform: translateY(-140%);
    -o-transform: translateY(-140%);
    -webkit-transform: translateY(-140%);
    left: .5rem;
    background: var(--sponsored);
    opacity: .7;
    color: var(--white);
    padding: .4rem .5rem .4rem .5rem;
    border-radius: 4px;
    font-size: .60rem;
    text-transform: uppercase;
    letter-spacing: 0px;
    font-weight: normal;
}


.img-container { /*para que los bloques en vivo estén absolutos en función del contenedor relativo*/
    position: relative;
}


@media (max-width: 1024px) {
    .parent {
        column-gap: 1.2rem;
    }
}

@media (max-width: 650px) {
.img-container{
    min-height: 229px;
	overflow: hidden;
    }
.grid-item h3 {
        font-size: 1.2rem;
        font-family: "Roboto", sans-serif;
        padding: 0 0 0.65rem 0;
    }
.note-author {
    margin-top: 0rem;
    font-size: 0.85rem;
}
}